<template>
  <base-section
    id="banner"
  >
    <v-parallax
    :height="$vuetify.breakpoint.smAndDown ? 500 : 400"
    src="@/assets/banner.jpg"
    style="background-image: linear-gradient(200deg,#0e469c, #0e469c);"
    >
      <v-container>
        <v-row
          class="mx-auto"
          v-for="value in stats"
          :key="value"
        >
          <div class="mx-auto text-center">
            <div
              class="text-h3 font-weight-bold"
              style="color:white;"
              v-text="value"
            ></div>
            <br>
          </div>
        </v-row>
      </v-container>
    </v-parallax>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionBanner',

    data: () => {
      return {
        stats: [
          'TRADING',
          'ENGINEERING',
          'TECHNOLOGY',
          'INNOVATION',
        ],
      }
    },
  }
</script>
